import React from "react"
// import { PreviewStoreProvider } from "gatsby-source-prismic"
import "typeface-quicksand"
import "./src/styles/global.css"


export const wrapRootElement = ({ element }) => {
  return (
    // <PreviewStoreProvider initialEnabled={true}>
    <>
      {element}
    </>
    // </PreviewStoreProvider>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
  location.state.referrer = prevLocation ? prevLocation.pathname : "/"

  if(document.getElementsByTagName('iframe').length > 0) {
    const $ = require("jquery")

    $(document).ready(function () {
      window.addEventListener('message', function(event) {
        // console.log('resize iframe')
        const frames = document.getElementsByTagName('iframe');
        for (let i = 0; i < frames.length; i++) {
          if (frames[i].contentWindow === event.source) {
            $(frames[i]).height(event.data);
            break;
          }
        }
      });
    });
  }
}

